@import "tailwindcss";
  
@theme {
    --breakpoint-sm: 480px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 976px;
    --breakpoint-xl: 1440px;

    --font-sans: "Graphik", sans-serif;
    --font-serif: "Merriweather", serif;
    --font-h1: "glegoo", serif;

    --color-orange: #f74902;
    --color-white: #ffffff;
    --color-dk-grey: #333333;
    --color-md-grey: #666666;
    --color-lt-grey: #cccccc;

    --text-responsive: clamp(1.875rem, 4vw, 2.75rem);
    --text-responsive-lg: clamp(4.5rem, 8vw, 8rem);
    --text-responsive-sm: clamp(1.5rem, 3vw, 2.25rem);
    --text-responsive-xs: clamp(1.25rem, 3vw, 2rem);

    --spacing-8xl: 96rem;
    --spacing-9xl: 128rem;

    --radius-4xl: 2rem;
}

@layer base {
    .people-mc {
        background-image: url('img/people/mickey-cohen.png'); /* Default fallback */
    }
    .people-nw {
        background-image: url('img/people/naoya-wada.png'); /* Default fallback */
    }
    .people-lk {
        background-image: url('img/people/lisa-katz.png'); /* Default fallback */
    }
    .people-hk {
        background-image: url('img/people/heidi-klein.png'); /* Default fallback */
    }
    .people-cj {
        background-image: url('img/people/caroline-johnson.png'); /* Default fallback */
    }
    
    @supports (background-image: url('img/people/mickey-cohen.png?as=webp')) {
        .people-mc {
            background-image: url('img/people/mickey-cohen.png?as=webp');
        }
    }
    @supports (background-image: url('img/people/naoya-wada.png?as=webp')) {
        .people-nw {
            background-image: url('img/people/naoya-wada.png?as=webp');
        }
    }
    @supports (background-image: url('img/people/lisa-katz.png?as=webp')) {
        .people-lk {
            background-image: url('img/people/lisa-katz.png?as=webp');
        }
    }
    @supports (background-image: url('img/people/heidi-klein.png?as=webp')) {
        .people-hk {
            background-image: url('img/people/heidi-klein.png?as=webp');
        }
    }
    @supports (background-image: url('img/people/caroline-johnson.png?as=webp')) {
        .people-cj {
            background-image: url('img/people/caroline-johnson.png?as=webp');
        }
    }

    @supports (background-image: url('img/people/mickey-cohen.png?as=avif')) {
        .people-mc {
            background-image: url('img/people/mickey-cohen.png?as=avif');
        }
    }
    @supports (background-image: url('img/people/naoya-wada.png?as=avif')) {
        .people-nw {
            background-image: url('img/people/naoya-wada.png?as=avif');
        }
    }
    @supports (background-image: url('img/people/lisa-katz.png?as=avif')) {
        .people-lk {
            background-image: url('img/people/lisa-katz.png?as=avif');
        }
    }
    @supports (background-image: url('img/people/heidi-klein.png?as=avif')) {
        .people-hk {
            background-image: url('img/people/heidi-klein.png?as=avif');
        }
    }
    @supports (background-image: url('img/people/caroline-johnson.png?as=avif')) {
        .people-cj {
            background-image: url('img/people/caroline-johnson.png?as=avif');
        }
    }
}
